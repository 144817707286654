import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex flex-wrap justify-center w-96 mx-auto" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["innerHTML"]

import { computed } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import Pagination from "@/types/Pagination";


export default /*@__PURE__*/_defineComponent({
  __name: 'PaginationComponent',
  props: {
    pagination: {}
  },
  emits: ["updated"],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emit = __emit;

const store = useStore();
const router = useRouter();
const route = useRoute();

const isDarkMode = computed(() => store.getters.isDarkMode);

const routeTo = (type: string): void => {
  if (!props.pagination || !props.pagination.page) return;

  let newPage: number | null = null;
  switch (type) {
    case "first":
      newPage = props.pagination.page.first;
      break;
    case "last":
      newPage = props.pagination.page.last;
      break;
    case "next":
      newPage = props.pagination.page.next;
      break;
    case "previous":
      newPage = props.pagination.page.previous;
      break;
    case "current":
      newPage = props.pagination.page.current;
      break;
  }

  if (!newPage) return;

  // Merge existing query parameters
  router.push({
    name: "Articles",
    query: { ...route.query, page: newPage }, // Preserve existing queries
  });

  emit("updated", newPage);
};

return (_ctx: any,_cache: any) => {
  return (_ctx.pagination && _ctx.pagination.page)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["fixed z-10 left-0 right-0 bottom-0 w-screen pb-2 border-white pt-2 border-t-2", [
      isDarkMode.value ? 'bg-gray-900 text-gray-100' : 'bg-gray-200 text-gray-900',
    ]])
      }, [
        _createElementVNode("div", _hoisted_1, [
          (_ctx.pagination.page.previous)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                class: _normalizeClass(["cursor-pointer select-none block px-3 py-4 mx-1 border-gray-300 border-2 hover:border-blue-300", [
          isDarkMode.value
            ? 'bg-gray-900 text-gray-100'
            : 'bg-gray-200 text-gray-900',
        ]]),
                onClick: _cache[0] || (_cache[0] = ($event: any) => (routeTo('previous')))
              }, "← ", 2))
            : _createCommentVNode("", true),
          (_ctx.pagination.page.first)
            ? (_openBlock(), _createElementBlock("a", {
                key: 1,
                class: _normalizeClass(["cursor-pointer select-none block px-3 py-4 mx-1 border-gray-300 border-2 hover:border-blue-300", [
          isDarkMode.value
            ? 'bg-gray-900 text-gray-100'
            : 'bg-gray-200 text-gray-900',
        ]]),
                onClick: _cache[1] || (_cache[1] = ($event: any) => (routeTo('first'))),
                innerHTML: _ctx.pagination.page.first
              }, null, 10, _hoisted_2))
            : _createCommentVNode("", true),
          (_ctx.pagination.page.current - 1 > 1)
            ? (_openBlock(), _createElementBlock("div", {
                key: 2,
                class: _normalizeClass(["block px-2 py-4 mx-1", [
          isDarkMode.value
            ? 'bg-gray-900 text-gray-100'
            : 'bg-gray-200 text-gray-900',
        ]])
              }, " ... ", 2))
            : _createCommentVNode("", true),
          (_ctx.pagination.page.current)
            ? (_openBlock(), _createElementBlock("a", {
                key: 3,
                class: _normalizeClass(["cursor-default select-none block px-2 py-4 mx-1 border-gray-400 border-2", [
          isDarkMode.value
            ? 'bg-gray-900 text-gray-100'
            : 'bg-gray-200 text-gray-900',
        ]]),
                innerHTML: _ctx.pagination.page.current
              }, null, 10, _hoisted_3))
            : _createCommentVNode("", true),
          (_ctx.pagination.page.last - _ctx.pagination.page.current > 1)
            ? (_openBlock(), _createElementBlock("div", {
                key: 4,
                class: _normalizeClass(["block px-2 py-4 mx-1", [
          isDarkMode.value
            ? 'bg-gray-900 text-gray-100'
            : 'bg-gray-200 text-gray-900',
        ]])
              }, " ... ", 2))
            : _createCommentVNode("", true),
          (_ctx.pagination.page.last)
            ? (_openBlock(), _createElementBlock("a", {
                key: 5,
                class: _normalizeClass(["cursor-pointer select-none block px-3 py-4 mx-1 border-gray-300 border-2 hover:border-blue-300", [
          isDarkMode.value
            ? 'bg-gray-900 text-gray-100'
            : 'bg-gray-200 text-gray-900',
        ]]),
                onClick: _cache[2] || (_cache[2] = ($event: any) => (routeTo('last'))),
                innerHTML: _ctx.pagination.page.last
              }, null, 10, _hoisted_4))
            : _createCommentVNode("", true),
          (_ctx.pagination.page.next)
            ? (_openBlock(), _createElementBlock("a", {
                key: 6,
                class: _normalizeClass(["cursor-pointer select-none block px-3 py-4 mx-1 border-gray-300 border-2 hover:border-blue-300", [
          isDarkMode.value
            ? 'bg-gray-900 text-gray-100'
            : 'bg-gray-200 text-gray-900',
        ]]),
                onClick: _cache[3] || (_cache[3] = ($event: any) => (routeTo('next')))
              }, "→ ", 2))
            : _createCommentVNode("", true)
        ])
      ], 2))
    : _createCommentVNode("", true)
}
}

})