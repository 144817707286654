import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex flex-wrap justify-center md:justify-start" }
const _hoisted_2 = { class: "input-group relative flex flex-shrink-0 items-stretch w-full md:w-56 mb-4 rounded md:mr-3" }
const _hoisted_3 = { class: "absolute z-20 mt-9 px-10 md:px-0 md:mt-8 w-full" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "block relative z-10 md:flex md:flex-grow-0 flex-wrap py-1 px-0 text-gray-900 font-semibold text-xs leading-10 md:leading-snug md:gap-x-1 md:gap-y-2" }
const _hoisted_6 = { class: "leading-8 md:leading-6 text-justify mb-6 font-nunito-sans" }
const _hoisted_7 = { class: "flex w-full border-b-2 border-color-gray-400" }
const _hoisted_8 = { class: "flex-auto text-left font-bold" }
const _hoisted_9 = { class: "hidden md:inline flex-auto text-right text-sm font-semibold" }
const _hoisted_10 = { class: "pt-4 flex space-x-8 w-full" }
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = {
  key: 0,
  class: "hidden xl:inline-flex w-3/12 flex-shrink-0"
}
const _hoisted_13 = ["src"]

import { ref, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import ArticleService from "@/services/ArticleService";
import SearchService from "@/services/SearchService";
import PaginationComponent from "@/components/PaginationComponent.vue";
import TagItem from "@/components/TagItem.vue";
import Article from "@/types/Article";
import ResponseData from "@/types/ResponseData";
import Pagination from "@/types/Pagination";


export default /*@__PURE__*/_defineComponent({
  __name: 'Articles',
  setup(__props) {

const route = useRoute();
const router = useRouter();

const pageNumber = ref(1);
const articles = ref<Article[]>([]);
const pagination = ref<Pagination>({ page: 1, links: [] });
const tags = ref<string[]>([]);
const searchedTags = ref<string[]>([]);
const searchText = ref("");

const getPageNumber = (): number => {
  const page = route.query.page;
  if (typeof page === "string") {
    return parseInt(page);
  }
  return 1;
};

const getTags = (): string[] => {
  const tags = route.query.tags;
  if (typeof tags === "undefined") {
    return [];
  } else if (typeof tags === "string") {
    return [tags];
  } else {
    return tags as string[];
  }
};

const retrieve = (pageNumber: number, tags?: string[]) => {
  ArticleService.getAll(pageNumber, tags)
    .then((response: ResponseData) => {
      articles.value = response.data.data;
      pagination.value = response.data.pagination;
    })
    .catch((e: Error) => {
      console.log(e);
    });
};

const searchAvailableTags = (tag: string) => {
  if (!tag) {
    searchedTags.value = [];
    return;
  }
  SearchService.searchAvailableTags(tag)
    .then((response: ResponseData) => {
      searchedTags.value = response.data.data.filter((tag: string) => {
        return !tags.value.includes(tag);
      });
    })
    .catch((e: Error) => {
      console.log(e);
    });
};

const removeTag = (tag: string) => {
  tags.value = tags.value.filter((existingTag) => existingTag !== tag);

  router.push({
    name: "Articles",
    query: {
      page: route.query.page ? pageNumber.value : undefined,
      tags: tags.value.length ? tags.value : undefined,
    },
  });

  retrieve(pageNumber.value, tags.value);
};

const addTag = (tag: string) => {
  if (tags.value.includes(tag)) {
    return;
  }
  tags.value.push(tag);
  router.push({
    name: "Articles",
    query: {
      page: route.query.page ? pageNumber.value : undefined,
      tags: tags.value,
    },
  });

  searchText.value = "";
  searchedTags.value = [];
  retrieve(pageNumber.value, tags.value);
};

const dateOnly = (dateString: string) => {
  const date = new Date(dateString);
  return (
    date.getDate() + " / " + (date.getMonth() + 1) + " / " + date.getFullYear()
  );
};

const summary = (text: string) => {
  const desiredMinLength = 600;
  if (!text) {
    return "";
  }
  const cleanTest = text
    .replace(/<figure[^>]*>(((?!figure).|[\n\r])*)<\/figure>/gim, "")
    .replace(
      /Click\s+<a[^>]+>[^<]+<\/a>\s+to\s+see\s+this\s+post\s+on\s+LinkedIn\./gi,
      ""
    )
    .replace(/<\/?[^>]+(>|$)/g, "")
    .substring(0, 1500);
  for (const wordNumber of Array.from({ length: 50 }, (_, i) => i * 10)) {
    if (
      cleanTest.split(" ").slice(0, wordNumber).join(" ").length >
      desiredMinLength
    ) {
      return cleanTest.split(" ").slice(0, wordNumber).join(" ") + " ...";
    }
  }
  return cleanTest.split(" ").slice(0, 300).join(" ");
};

onMounted(() => {
  pageNumber.value = getPageNumber();
  tags.value = getTags();
  retrieve(pageNumber.value, tags.value);
});

watch(route, () => {
  pageNumber.value = getPageNumber();
  tags.value = getTags();
  retrieve(pageNumber.value, tags.value);
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[3] || (_cache[3] = _createElementVNode("h2", { class: "text-2xl font-bold" }, "Articles", -1)),
    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "mb-2 border-t-2 border-white pr-1 hidden md:block" }, [
      _createElementVNode("span", { class: "text-sm font-bold float-right text-gray-500 dark:text-gray-300 pt-4 lg:pt-1" }, [
        _createTextVNode(" medium.com: "),
        _createElementVNode("a", {
          target: "_blank",
          class: "hover:text-gray-600 dark:hover:text-gray-400",
          href: "https://medium.com/@pooyan_razian"
        }, " @pooyan_razian ")
      ])
    ], -1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchText).value = $event)),
          type: "search",
          class: "form-control relative flex-auto min-w-0 block w-full pl-3 pr-8 py-2 text-xs font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-gray-400 focus:outline-none",
          placeholder: "Search by tag...",
          onInput: _cache[1] || (_cache[1] = ($event: any) => (searchAvailableTags(searchText.value)))
        }, null, 544), [
          [_vModelText, searchText.value]
        ]),
        _cache[2] || (_cache[2] = _createElementVNode("svg", {
          "aria-hidden": "true",
          focusable: "false",
          "data-prefix": "fas",
          "data-icon": "search",
          class: "w-9 absolute right-0 py-2 px-2 text-gray-300",
          role: "img",
          xmlns: "http://www.w3.org/2000/svg",
          viewBox: "0 0 512 512"
        }, [
          _createElementVNode("path", {
            fill: "currentColor",
            d: "M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
          })
        ], -1))
      ]),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(searchedTags.value, (searchedTag, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "flex flex-shrink-0 w-full z-20 py-4 px-12 md:w-56 md:py-2 md:pl-2 border-b border-gray-100 bg-gray-400 text-gray-900 font-semibold text-xs cursor-pointer hover:bg-blue-500",
            onClick: ($event: any) => (addTag(searchedTag))
          }, _toDisplayString(searchedTag), 9, _hoisted_4))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tags.value, (tag, index) => {
          return (_openBlock(), _createBlock(TagItem, {
            key: index,
            tag: tag,
            onRemove: removeTag
          }, null, 8, ["tag"]))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(articles.value, (article, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "pb-6"
        }, [
          _createVNode(_component_router_link, {
            to: '/articles/' + article.slug
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, _toDisplayString(article.title), 1),
                _createElementVNode("div", _hoisted_9, _toDisplayString(dateOnly(article.publishedAt)), 1)
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", {
                  class: "w-7/12 flex-grow text-gray-700 dark:text-gray-400",
                  innerHTML: summary(article.content)
                }, null, 8, _hoisted_11),
                (article.mainImage)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                      _createElementVNode("img", {
                        src: article.mainImage,
                        class: "w-full rounded-l-md opacity-90"
                      }, null, 8, _hoisted_13)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 2
          }, 1032, ["to"])
        ]))
      }), 128))
    ]),
    _createVNode(PaginationComponent, {
      pagination: pagination.value,
      onUpdated: retrieve
    }, null, 8, ["pagination"])
  ], 64))
}
}

})